var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useRouteMatch } from 'react-router-dom';
import { format, parseJSON } from 'date-fns';
export var FlyerTile = function (_a) {
    var _b = _a.flyerid, flyerid = _b === void 0 ? '' : _b, saledate = _a.saledate, size = _a.size;
    var url = useRouteMatch().url;
    return (_jsx("div", __assign({ className: "flex items-start rounded-lg shadow-lg hover:shadow-md transition-shadow  border border-gray-100" }, { children: _jsxs(Link, __assign({ to: url + "/" + flyerid, className: "p-8 inline-block w-full" }, { children: [_jsx("p", __assign({ className: "font-bold text-xl mb-2 text-mmorange" }, { children: format(parseJSON(saledate), 'MM/dd/y') }), void 0), _jsx("p", __assign({ className: "font-bold text-lg mb-1" }, { children: size }), void 0)] }), void 0) }), void 0));
};
FlyerTile.displayName = 'FlyerTile';
