var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var Checkbox = function (_a) {
    var label = _a.label, _b = _a.id, id = _b === void 0 ? '' : _b, _c = _a.value, value = _c === void 0 ? false : _c, _d = _a.className, className = _d === void 0 ? 'w-6 h-6 border border-gray-400 appearance-none inline-block text-sky-600 bg-white rounded-md checked:bg-checkbox checked:bg-current checked:bg-auto checked:bg-center checked:border-sky-600 focus:outline-none focus:ring-sky-600' : _d, _e = _a.onChange, onChange = _e === void 0 ? function () { return null; } : _e;
    return (_jsxs("label", __assign({ className: "flex items-center" }, { children: [_jsx("input", { className: className, type: "checkbox", id: id, name: id, checked: value, onChange: onChange, onBlur: onChange }, void 0), _jsx("span", __assign({ className: "inline-block ml-3" }, { children: label }), void 0)] }), void 0));
};
Checkbox.displayName = 'Checkbox';
