var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { loginRequest, getUserRequest } from '../requests/sessions';
var BROWSER_AUTH_COOKIE = process.env.BROWSER_AUTH_COOKIE || 'MARKETS_TOKEN';
export var AUTH_ROUTES = ['/login', '/forgot-password', '/reset-password'];
var AuthContext = createContext({});
export function AuthProvider(_a) {
    var children = _a.children;
    var _b = __read(useState(), 2), user = _b[0], setUser = _b[1];
    var _c = __read(useState(), 2), error = _c[0], setError = _c[1];
    var _d = __read(useState(''), 2), role = _d[0], setRole = _d[1];
    var _e = __read(useState(false), 2), isAdmin = _e[0], setIsAdmin = _e[1];
    var _f = __read(useState(false), 2), isPrintBuyer = _f[0], setIsPrintBuyer = _f[1];
    var _g = __read(useState(false), 2), loading = _g[0], setLoading = _g[1];
    var _h = __read(useState(true), 2), loadingInitial = _h[0], setLoadingInitial = _h[1];
    var _j = __read(useState(false), 2), limitEdit = _j[0], setLimitEdit = _j[1];
    var _k = __read(useState(false), 2), isCorpOnly = _k[0], setCorpOnly = _k[1];
    var _l = __read(useState(false), 2), isLeadOnly = _l[0], setLeadOnly = _l[1];
    var history = useHistory();
    var location = useLocation();
    useEffect(function () {
        if (error)
            setError(null);
    }, [location === null || location === void 0 ? void 0 : location.pathname]);
    useEffect(function () {
        if (!AUTH_ROUTES.includes(location === null || location === void 0 ? void 0 : location.pathname)) {
            getUserRequest()
                .then(function (user) {
                if (user.success) {
                    if (user.data.role === 'ADMIN' || user.data.role === 'CSR') {
                        setIsAdmin(true);
                    }
                    if (user.data.role === 'PRINT_BUYER') {
                        setIsPrintBuyer(true);
                        setLimitEdit(true);
                    }
                    if (user.data.role === 'FRANCHISEE') {
                        setLimitEdit(true);
                    }
                    if (user.data.role === 'AREA_MANAGER_CORPORATE') {
                        setCorpOnly(true);
                    }
                    if (user.data.role === 'LEAD_CORPORATE') {
                        setLeadOnly(true);
                    }
                    setRole(user.data.role);
                    setUser(user.data);
                }
                else {
                    setError(user.error);
                }
            })
                .catch(function (_error) {
                setError(_error);
            })
                .finally(function () { return setLoadingInitial(false); });
        }
        else {
            setLoadingInitial(false);
        }
    }, []);
    function login(email, password) {
        setLoading(true);
        try {
        }
        catch (err) { }
        loginRequest({ email: email, password: password })
            .then(function (user) {
            if (!user.success) {
                // throw user.information;
                throw 'INVALID_LOGIN';
            }
            else {
                setUser(user.data);
                setRole(user.data.role);
                if (user.data.role === 'ADMIN' || user.data.role === 'CSR') {
                    setIsAdmin(true);
                }
                if (user.data.role === 'PRINT_BUYER') {
                    setIsPrintBuyer(true);
                    setLimitEdit(true);
                }
                if (user.data.role === 'FRANCHISEE') {
                    setLimitEdit(true);
                }
                if (user.data.role === 'AREA_MANAGER_CORPORATE') {
                    setCorpOnly(true);
                }
                if (user.data.role === 'LEAD_CORPORATE') {
                    setLeadOnly(true);
                }
                history.push('/');
            }
        })
            .catch(function (error) { return setError(error); })
            .finally(function () { return setLoading(false); });
    }
    function logout() {
        Cookies.remove(BROWSER_AUTH_COOKIE);
        setUser(undefined);
        setRole('');
        setIsAdmin(false);
        setIsPrintBuyer(false);
        history.push('/login');
    }
    var memoizedValue = useMemo(function () { return ({
        user: user,
        loading: loading,
        error: error,
        login: login,
        logout: logout,
        isAdmin: isAdmin,
        isPrintBuyer: isPrintBuyer,
        role: role,
        limitEdit: limitEdit,
        isCorpOnly: isCorpOnly,
        isLeadOnly: isLeadOnly,
    }); }, [user, loading, error, isAdmin, isPrintBuyer, role, limitEdit, isCorpOnly, isLeadOnly]);
    return _jsx(AuthContext.Provider, __assign({ value: memoizedValue }, { children: !loadingInitial && children }), void 0);
}
export default function useAuth() {
    return useContext(AuthContext);
}
