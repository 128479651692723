var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* This example requires Tailwind CSS v2.0+ */
import { HomeIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
export var Breadcrumbs = function (_a) {
    var links = _a.links;
    return (_jsx("nav", __assign({ className: "flex mb-8", "aria-label": "Breadcrumb" }, { children: _jsxs("ol", __assign({ className: "flex items-center space-x-2" }, { children: [_jsx("li", { children: _jsx("div", { children: _jsxs(Link, __assign({ to: "/", className: "text-sm text-gray-500 hover:text-gray-700 flex items-center" }, { children: [_jsx(HomeIcon, { className: "flex-shrink-0 h-5 w-5 mr-2", "aria-hidden": "true" }, void 0), "Home"] }), void 0) }, void 0) }, void 0), links.map(function (page) { return (_jsx("li", { children: _jsxs("div", __assign({ className: "flex items-center" }, { children: [_jsx("svg", __assign({ className: "flex-shrink-0 h-5 w-5 text-gray-300", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", viewBox: "0 0 20 20", "aria-hidden": "true" }, { children: _jsx("path", { d: "M5.555 17.776l8-16 .894.448-8 16-.894-.448z" }, void 0) }), void 0), _jsx(Link, __assign({ to: "" + page.href, className: clsx('ml-4 text-sm text-gray-500 hover:text-gray-700 hover:underline', page.current && 'text-gray-700 hover:text-gray-500 font-medium'), "aria-current": page.current ? 'page' : undefined }, { children: page.name }), void 0)] }), void 0) }, page.name)); })] }), void 0) }), void 0));
};
