var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
export var FlyerInfo = function (_a) {
    var flyerid = _a.flyerid, saleDate = _a.saleDate, pageCount = _a.pageCount, size = _a.size, type = _a.type;
    var t = useTranslation().t;
    return (_jsxs("div", __assign({ className: "rounded-lg shadow-lg overflow-hidden border border-gray-100 mb-6" }, { children: [_jsx("header", __assign({ className: "py-4 px-8 flex justify-between" }, { children: _jsx("h1", __assign({ className: "text-xl text-mmorange" }, { children: new Date(saleDate).toLocaleDateString(t('locale:const'), {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                    }) }), void 0) }), void 0), _jsxs("div", __assign({ className: "px-8 grid grid-cols-2 mb-8 gap-4" }, { children: [_jsxs("div", { children: [_jsx("h3", __assign({ className: "text-gray-900" }, { children: t('models:flyer.format') }), void 0), _jsxs("p", __assign({ className: "text-gray-500 text-sm" }, { children: [pageCount, " Pg ", size] }), void 0)] }, void 0), _jsxs("div", { children: [_jsx("h3", __assign({ className: "text-gray-900" }, { children: t('models:flyer.type') }), void 0), _jsx("p", __assign({ className: "text-gray-500 text-sm" }, { children: t("flyers:" + type) }), void 0)] }, void 0)] }), void 0)] }), void 0));
};
FlyerInfo.displayName = 'FlyerInfo';
