var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
export var Input = function (_a) {
    var label = _a.label, _b = _a.id, id = _b === void 0 ? '' : _b, _c = _a.type, type = _c === void 0 ? 'text' : _c, _d = _a.value, value = _d === void 0 ? '' : _d, _e = _a.required, required = _e === void 0 ? false : _e, _f = _a.className, className = _f === void 0 ? '' : _f, _g = _a.onChange, onChange = _g === void 0 ? function () { return null; } : _g;
    var _h = __read(useState(false), 2), valid = _h[0], setValid = _h[1];
    var handleChange = function (e) {
        onChange(e.target.value);
        if (e.target.value.length && !valid) {
            setValid(true);
        }
        else if (!e.target.value.length && valid) {
            setValid(false);
        }
    };
    return (_jsxs("div", __assign({ className: "text-left w-full" }, { children: [_jsx("label", __assign({ htmlFor: id, className: "block text-sm font-medium text-gray-700 mb-1" }, { children: label }), void 0), _jsx("input", { id: id, onChange: handleChange, onBlur: handleChange, type: type, required: required, value: value, className: "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm shadow-zinc-200 placeholder-gray-400 focus:outline-none focus:ring-sky-500 focus:border-sky-500 focus:shadow-sky-200 sm:text-sm" }, void 0)] }), void 0));
};
Input.displayName = 'Input';
