var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { motion } from 'framer-motion';
var Path = function (props) { return (_jsx(motion.path, __assign({ fill: "transparent", strokeWidth: "2", stroke: "hsl(0, 0%, 100%)", strokeLinecap: "round" }, props), void 0)); };
export var MenuToggle = function (_a, ref) {
    var toggle = _a.toggle;
    return (_jsxs("button", __assign({ ref: ref, onClick: toggle, title: "Toggle Menu", className: "appearance-none background-transparent border-0 cursor-pointer w-6 md:w-12 h-6 md:h-12 relative" }, { children: [_jsx("span", __assign({ className: "sr-only" }, { children: "Toggle Menu" }), void 0), _jsxs("svg", __assign({ className: "h-6 w-6 md:h-12 md:w-10", viewBox: "0 0 23 23" }, { children: [_jsx(Path, { variants: {
                            closed: { d: 'M 2 2.5 L 20 2.5' },
                            open: { d: 'M 3 16.5 L 17 2.5' },
                        } }, void 0), _jsx(Path, { d: "M 2 9.423 L 20 9.423", variants: {
                            closed: { opacity: 1 },
                            open: { opacity: 0 },
                        }, transition: { duration: 0.1 } }, void 0), _jsx(Path, { variants: {
                            closed: { d: 'M 2 16.346 L 20 16.346' },
                            open: { d: 'M 3 2.5 L 17 16.346' },
                        } }, void 0)] }), void 0)] }), void 0));
};
MenuToggle.displayName = 'MenuToggle';
