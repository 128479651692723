var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect } from 'react';
import shallow from 'zustand/shallow';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationIcon, XCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { useUIStore } from '../state';
export default function Notification() {
    var _a = useUIStore(function (_a) {
        var notificationTitle = _a.notificationTitle, notificationActive = _a.notificationActive, notificationMessage = _a.notificationMessage, clearNotification = _a.clearNotification, notificationType = _a.notificationType;
        return ({
            notificationTitle: notificationTitle,
            notificationActive: notificationActive,
            notificationMessage: notificationMessage,
            clearNotification: clearNotification,
            notificationType: notificationType,
        });
    }, shallow), notificationTitle = _a.notificationTitle, notificationActive = _a.notificationActive, notificationMessage = _a.notificationMessage, clearNotification = _a.clearNotification, notificationType = _a.notificationType;
    var icon = _jsx(CheckCircleIcon, { className: "h-6 w-6 text-green-400", "aria-hidden": "true" }, void 0);
    if (notificationType === 'warning') {
        icon = _jsx(ExclamationIcon, { className: "h-6 w-6 text-yellow-400", "aria-hidden": "true" }, void 0);
    }
    else if (notificationType === 'error') {
        icon = _jsx(XCircleIcon, { className: "h-6 w-6 text-red-400", "aria-hidden": "true" }, void 0);
    }
    useEffect(function () {
        setTimeout(function () {
            if (notificationActive) {
                clearNotification();
            }
        }, 4000);
    }, [notificationActive]);
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ "aria-live": "assertive", className: "fixed top-24 right-0 w-full max-w-md flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-[99999]" }, { children: _jsx("div", __assign({ className: "w-full flex flex-col items-center space-y-4 sm:items-end" }, { children: _jsx(Transition, __assign({ show: notificationActive, as: Fragment, enter: "transform ease-out duration-300 transition", enterFrom: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2", enterTo: "translate-y-0 opacity-100 sm:translate-x-0", leave: "transition ease-in duration-100", leaveFrom: "opacity-100", leaveTo: "opacity-0" }, { children: _jsx("div", __assign({ className: "max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden" }, { children: _jsx("div", __assign({ className: "p-4" }, { children: _jsxs("div", __assign({ className: "flex items-start" }, { children: [_jsx("div", __assign({ className: "flex-shrink-0" }, { children: icon }), void 0), _jsxs("div", __assign({ className: "ml-3 w-0 flex-1" }, { children: [_jsx("p", __assign({ className: "text-shadow font-medium" }, { children: notificationTitle }), void 0), _jsx("p", __assign({ className: "mt-1 text-gray-500" }, { children: notificationMessage }), void 0)] }), void 0), _jsx("div", __assign({ className: "ml-4 flex-shrink-0 flex" }, { children: _jsxs("button", __assign({ className: "bg-white rounded-md inline-flex text-shadow hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500", onClick: clearNotification }, { children: [_jsx("span", __assign({ className: "sr-only" }, { children: "Close" }), void 0), _jsx(XIcon, { className: "h-5 w-5", "aria-hidden": "true" }, void 0)] }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0) }), void 0) }), void 0) }, void 0));
}
