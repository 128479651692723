var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LockClosedIcon } from '@heroicons/react/solid';
import useAuth from '../../hooks/useAuth';
import enLogo from '../../assets/mm_en-blue.png';
import frLogo from '../../assets/mm_fr-blue.png';
import { Button, Input } from '../../Components';
import { useUIStore } from '../../state';
var Login = function () {
    var _a = useForm(), handleSubmit = _a.handleSubmit, control = _a.control;
    var _b = useTranslation(), t = _b.t, i18n = _b.i18n;
    var _c = useAuth(), login = _c.login, loading = _c.loading, error = _c.error, user = _c.user, logout = _c.logout;
    var setNotification = useUIStore(function (_a) {
        var setNotification = _a.setNotification;
        return setNotification;
    });
    var onSubmit = function (_a) {
        var email = _a.email, password = _a.password;
        return login(email, password);
    };
    useEffect(function () {
        console.log(onSubmit);
        logout();
    }, []);
    return (_jsx("div", __assign({ className: "bg-gray-50 flex flex-col items-center min-h-screen justify-center px-5 py-6" }, { children: _jsxs("div", __assign({ className: "mt-8 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 text-center w-full max-w-xl" }, { children: [_jsx("div", __assign({ className: "h-20 mb-4 flex justify-center" }, { children: _jsx("img", { src: i18n.language === 'en' ? enLogo : frLogo, className: "h-20", alt: "M&M Markets Logo" }, void 0) }), void 0), _jsx("h1", __assign({ className: "text-3xl tracking-tight mb-4" }, { children: t('login:title') }), void 0), _jsx("p", __assign({ className: "text-lg tracking-tight mb-8" }, { children: t('login:headline') }), void 0), _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit), className: "flex flex-col items-center space-y-5 px-24" }, { children: [_jsx(Controller, { control: control, name: "email", render: function (_a) {
                                var _b = _a.field, onChange = _b.onChange, value = _b.value, name = _b.name;
                                return (_jsx(Input, { value: value, onChange: onChange, type: "email", id: name, label: t('global:email'), required: true }, void 0));
                            } }, void 0), _jsx(Controller, { control: control, name: "password", render: function (_a) {
                                var _b = _a.field, onChange = _b.onChange, value = _b.value, name = _b.name;
                                return (_jsx(Input, { value: value, onChange: onChange, type: "password", id: name, label: t('global:password'), required: true }, void 0));
                            } }, void 0), error && _jsx("p", __assign({ className: "text-red-500" }, { children: t("api:" + error) }), void 0), _jsx("p", { children: _jsx(Link, __assign({ to: "/forgot-password", className: "text-sm text-darkblue underline" }, { children: t('login:forgot_password') }), void 0) }, void 0), _jsx("div", __assign({ className: "mt-16" }, { children: _jsxs(Button, __assign({ type: "submit" }, { children: [_jsx(LockClosedIcon, { className: "relative h-4 w-4 mr-1 text-blue", "aria-hidden": "true" }, void 0), t('login:log_in')] }), void 0) }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export default Login;
