var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm, Controller } from 'react-hook-form';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAPI from '../../hooks/useAPI';
import enLogo from '../../assets/mm_en-blue.png';
import frLogo from '../../assets/mm_fr-blue.png';
import { Button, Input } from '../../Components';
var ForgotPassword = function () {
    var _a = __read(useState(false), 2), showSuccess = _a[0], setShowSuccess = _a[1];
    var _b = useForm(), handleSubmit = _b.handleSubmit, control = _b.control;
    var _c = useTranslation(), t = _c.t, i18n = _c.i18n;
    var _d = useAPI(), post = _d.post, loading = _d.loading, error = _d.error;
    var onSubmit = function (_a) {
        var email = _a.email;
        return __awaiter(void 0, void 0, void 0, function () {
            var data;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, post('auth/forgotpassword', { email: email })];
                    case 1:
                        data = _b.sent();
                        if (data.success) {
                            setShowSuccess(true);
                        }
                        else {
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return (_jsx("div", __assign({ className: "bg-gray-50 flex flex-col items-center min-h-screen justify-center px-5 py-6" }, { children: _jsxs("div", __assign({ className: "mt-8 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 text-center w-full max-w-xl" }, { children: [_jsx("div", __assign({ className: "h-20 flex justify-center mb-4" }, { children: _jsx("img", { src: i18n.language === 'en' ? enLogo : frLogo, className: "h-20", alt: "M&M Markets Logo" }, void 0) }), void 0), _jsx("p", __assign({ className: "text-lg tracking-tight mb-8" }, { children: t('forgotPassword:forgot_password_headline') }), void 0), _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit), className: "flex flex-col items-center space-y-5 px-24" }, { children: [_jsx(Controller, { control: control, name: "email", render: function (_a) {
                                var _b = _a.field, onChange = _b.onChange, value = _b.value, name = _b.name;
                                return (_jsx(Input, { value: value, onChange: onChange, type: "email", id: name, label: t('global:email'), required: true }, void 0));
                            } }, void 0), error && _jsx("p", __assign({ className: "text-red-500" }, { children: t("api:" + error) }), void 0), showSuccess && _jsx("p", __assign({ className: "text-green-500" }, { children: t('forgotPassword:password_email_success') }), void 0), _jsx("div", __assign({ className: "mt-16" }, { children: _jsx(Button, __assign({ type: "submit", loading: loading }, { children: t('global:reset') }), void 0) }), void 0), _jsx("p", { children: _jsx(Link, __assign({ to: "/login", className: "text-darkblue underline text-sm" }, { children: t('global:back_to_login') }), void 0) }, void 0)] }), void 0)] }), void 0) }), void 0));
};
export default ForgotPassword;
