var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import omit from 'lodash.omit';
// import pickBy from 'lodash.pickby';
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { RefreshIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import useAPI from '../hooks/useAPI';
import { Loading, Pagination, Input, Button } from './index';
// import { filter } from 'lodash';
import Select from 'react-select';
export var SelectBulkStores = function (_a) {
    var _b = _a.searchTitle, searchTitle = _b === void 0 ? 'Search Value' : _b, endpoint = _a.endpoint, _c = _a.shouldParseProvinces, shouldParseProvinces = _c === void 0 ? false : _c, _d = _a.shouldParseZones, shouldParseZones = _d === void 0 ? false : _d, children = _a.children, _e = _a.title, title = _e === void 0 ? '' : _e, _f = _a.model, model = _f === void 0 ? '' : _f, _g = _a.showFilters, showFilters = _g === void 0 ? true : _g, _h = _a.canEdit, canEdit = _h === void 0 ? false : _h;
    var pathname = useLocation().pathname;
    var _j = useForm(), control = _j.control, handleSubmit = _j.handleSubmit, register = _j.register, watch = _j.watch, reset = _j.reset, formState = _j.formState;
    var t = useTranslation().t;
    var _k = useAPI(), get = _k.get, loading = _k.loading;
    var _l = __read(useState(null), 2), pageData = _l[0], setPageData = _l[1];
    var _m = __read(useState([]), 2), data = _m[0], setData = _m[1];
    var _o = __read(useState([]), 2), allData = _o[0], setAllData = _o[1];
    var _p = __read(useState(false), 2), showClear = _p[0], setShowClear = _p[1];
    var _q = __read(useState(''), 2), dataParams = _q[0], setParams = _q[1];
    var _r = __read(useState(false), 2), error = _r[0], setError = _r[1];
    var _s = __read(useState([]), 2), provinces = _s[0], setProvinces = _s[1]; // these are the selected provinces
    var _t = __read(useState([]), 2), zones = _t[0], setZones = _t[1];
    var defaultValues = {
        provinces: [],
        zones: [],
    };
    // const asList = useUIStore((state: UIState) => state.asList);
    // const start = watch('startDate');
    // const end = watch('endDate');
    useEffect(function () {
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var dist;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get(endpoint + "?page=1&active=true")];
                    case 1:
                        dist = _a.sent();
                        if (dist.success) {
                            setData(dist.data.data);
                            setAllData(dist.data.alldata);
                            if (shouldParseProvinces) {
                                parseProvinces(dist.data.regions);
                            }
                            if (shouldParseZones) {
                                parseZones(dist.data.zones);
                            }
                            setPageData(dist.data.pages);
                        }
                        else {
                            setError(true);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        fetchData();
    }, []);
    var parseProvinces = function (dataFeed) {
        var dataRegions = dataFeed.map(function (info) { return ({
            label: info.label,
            value: info.name,
        }); });
        setProvinces(dataRegions);
    };
    var parseZones = function (dataFeed) {
        var dataZones = dataFeed.map(function (info) { return ({
            label: info.label,
            value: info.name,
        }); });
        setZones(dataZones);
    };
    var handleNext = function () { return __awaiter(void 0, void 0, void 0, function () {
        var dist;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!pageData) return [3 /*break*/, 2];
                    return [4 /*yield*/, get(endpoint + "?page=" + (Number(pageData === null || pageData === void 0 ? void 0 : pageData.currentPage) + 1) + "&" + dataParams)];
                case 1:
                    dist = _a.sent();
                    if (dist.success) {
                        setData(dist.data.data);
                        if (shouldParseProvinces) {
                            parseProvinces(dist.data.regions);
                        }
                        setPageData(dist.data.pages);
                    }
                    else {
                        setError(true);
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var handlePrev = function () { return __awaiter(void 0, void 0, void 0, function () {
        var dist;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!pageData) return [3 /*break*/, 2];
                    return [4 /*yield*/, get(endpoint + "?page=" + (Number(pageData === null || pageData === void 0 ? void 0 : pageData.currentPage) - 1) + dataParams)];
                case 1:
                    dist = _a.sent();
                    if (dist.success) {
                        setData(dist.data.data);
                        setAllData(dist.data.alldata);
                        if (shouldParseProvinces) {
                            parseProvinces(dist.data.regions);
                        }
                        setPageData(dist.data.pages);
                    }
                    else {
                        setError(true);
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var handleClear = function () { return __awaiter(void 0, void 0, void 0, function () {
        var dist, onClear;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, get(endpoint + "?page=" + Number(pageData === null || pageData === void 0 ? void 0 : pageData.currentPage))];
                case 1:
                    dist = _a.sent();
                    if (dist.success) {
                        onClear = function () { return setData(dist.data.data); };
                        setPageData(dist.data.pages);
                        if (shouldParseProvinces) {
                            parseProvinces(dist.data.regions);
                        }
                        if (shouldParseZones) {
                            parseZones(dist.data.zones);
                        }
                        reset(defaultValues);
                        setShowClear(false);
                    }
                    else {
                        setError(true);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var search, provinces, zones, active, params, dist;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    search = data.search;
                    provinces = data.provinces;
                    zones = data.zones;
                    active = true;
                    if (!(provinces || zones || search || active)) return [3 /*break*/, 2];
                    params = '';
                    if (active) {
                        params += "&active=" + active;
                    }
                    if (search) {
                        params += "&search=" + search;
                    }
                    if (provinces === null || provinces === void 0 ? void 0 : provinces.length) {
                        params += "&province=" + provinces.join(',');
                    }
                    if (zones === null || zones === void 0 ? void 0 : zones.length) {
                        params += "&zone=" + zones.join(',');
                    }
                    return [4 /*yield*/, get(endpoint + "?page=1" + params)];
                case 1:
                    dist = _a.sent();
                    setParams(params);
                    setData(dist.data.data);
                    setAllData(dist.data.alldata);
                    setPageData(dist.data.pages);
                    if (shouldParseProvinces) {
                        parseProvinces(dist.data.regions);
                    }
                    setShowClear(true);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var renderData = function () {
        if (loading) {
            _jsx("div", __assign({ className: "flex justify-center" }, { children: _jsx(Loading, {}, void 0) }), void 0);
        }
        else if (!loading && data.length) {
            return (_jsxs("div", __assign({ className: "space-y-4 mb-6" }, { children: [pageData && showFilters && (_jsx(Pagination, { length: pageData.end, total: pageData.total, pages: pageData.pages, index: pageData.start, currentPage: pageData.currentPage, handleNext: handleNext, handlePrev: handlePrev }, void 0)), formState.isSubmitted && showClear ? (_jsx("button", __assign({ onClick: handleClear, className: "flex justify-center px-2 py-1.5 text-sm font-medium text-blue-900 bg-blue-100 border border-blue-900 border-opacity-40 rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 shadow-md shadow-blue-100" }, { children: t('global:clearSearch') }), void 0)) : null, children({
                        dataFeed: data,
                        allDataFeed: allData,
                        // asList,
                    }), pageData && showFilters && (_jsx(Pagination, { length: pageData.end, total: pageData.total, pages: pageData.pages, index: pageData.start, currentPage: pageData.currentPage, handleNext: handleNext, handlePrev: handlePrev }, void 0))] }), void 0));
        }
        else {
            // return <div>No data returned!</div>;
            return (_jsxs("div", __assign({ className: "flex flex-col justify-center items-center text-center max-w-4xl mx-auto space-y-6 mt-16" }, { children: [_jsxs("h2", __assign({ className: "text-2xl text-gray-700" }, { children: ["No ", model, " found."] }), void 0), _jsxs(Link, __assign({ to: "" + pathname, className: "flex items-center justify-center px-4 py-2 font-medium text-emerald-900 bg-emerald-100 border border-emerald-900 border-emerald-20 rounded-md hover:bg-emerald-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-emerald-500 shadow-lg shadow-emerald-100" }, { children: [_jsx(RefreshIcon, { className: "h-4 w-4 mr-1", "aria-hidden": "true" }, void 0), " ", t('global:reload')] }), void 0)] }), void 0));
        }
    };
    return (_jsxs("div", __assign({ className: "py-4 px-8" }, { children: [_jsx("header", __assign({ className: "flex items-baseline justify-between mb-6 pb-3 border-b border-gray-200" }, { children: _jsx("h1", __assign({ className: "text-2xl" }, { children: title }), void 0) }), void 0), _jsxs("div", __assign({ className: "flex" }, { children: [showFilters && !loading && !!data.length && (
                    // <aside className="pt-4 pb-24 space-y-8 divide-y">
                    _jsx("aside", __assign({ className: "pt-4 pb-8 divide-y w-1/5" }, { children: _jsx("div", __assign({ className: clsx(canEdit ? 'pt-1' : 'pt-0') }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit), className: "space-y-8 flex flex-col" }, { children: [_jsx("div", { children: _jsx(Controller, { control: control, name: "search", render: function (_a) {
                                                var _b = _a.field, onChange = _b.onChange, value = _b.value, name = _b.name;
                                                return (_jsx(Input, { value: value, onChange: onChange, type: "search", id: name, label: searchTitle }, void 0));
                                            } }, void 0) }, void 0), shouldParseProvinces && provinces.length ? (_jsxs("div", { children: [_jsx("h3", __assign({ className: "text-darkblue mb-2" }, { children: t('nav:provinces:label') }), void 0), _jsx("div", __assign({ className: "space-y-4 mb-4 flex-wrap " }, { children: _jsx(Controller, { defaultValue: [], control: control, name: "provinces", render: function (_a) {
                                                        var _b = _a.field, onChange = _b.onChange, value = _b.value, ref = _b.ref;
                                                        return (_jsx(Select, { ref: ref, "aria-labelledby": "provinces-label", value: provinces.filter(function (c) { return value.includes(c.value); }), onChange: function (val) { return onChange(val.map(function (c) { return c.value; })); }, options: provinces, isMulti: true, isClearable: false, hideSelectedOptions: true }, void 0));
                                                    } }, void 0) }), void 0)] }, void 0)) : null, shouldParseZones && zones.length ? (_jsxs("div", { children: [_jsx("h3", __assign({ className: "text-darkblue mb-2" }, { children: t('nav:zones:label') }), void 0), _jsx("div", __assign({ className: "space-y-4 mb-4" }, { children: _jsx(Controller, { defaultValue: [], control: control, name: "zones", render: function (_a) {
                                                        var _b = _a.field, onChange = _b.onChange, value = _b.value, ref = _b.ref;
                                                        return (_jsx(Select, { ref: ref, "aria-labelledby": "zones-label", value: zones.filter(function (c) { return value.includes(c.value); }), onChange: function (val) { return onChange(val.map(function (c) { return c.value; })); }, options: zones, isMulti: true, isClearable: false, hideSelectedOptions: true }, void 0));
                                                    } }, void 0) }), void 0)] }, void 0)) : null, _jsx(Button, __assign({ type: "submit" }, { children: t('global:search') }), void 0)] }), void 0) }), void 0) }), void 0)), _jsx("main", __assign({ className: clsx('flex-1', showFilters && !loading && !!data.length && 'w-3/4 ml-4') }, { children: renderData() }), void 0)] }), void 0)] }), void 0));
};
SelectBulkStores.displayName = 'SelectBulkStores';
