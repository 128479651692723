var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
export var Pagination = function (_a) {
    var _b = _a.currentPage, currentPage = _b === void 0 ? 1 : _b, _c = _a.index, index = _c === void 0 ? 1 : _c, _d = _a.length, length = _d === void 0 ? 50 : _d, _e = _a.total, total = _e === void 0 ? 1 : _e, _f = _a.pages, pages = _f === void 0 ? 1 : _f, _g = _a.handleNext, handleNext = _g === void 0 ? function () { return null; } : _g, _h = _a.handlePrev, handlePrev = _h === void 0 ? function () { return null; } : _h;
    var t = useTranslation().t;
    return (_jsxs("nav", __assign({ className: "bg-white p-2 flex items-center justify-between", "aria-label": "Pagination" }, { children: [_jsx("div", __assign({ className: "hidden sm:block" }, { children: _jsxs("p", __assign({ className: "text-sm text-gray-700" }, { children: [t('global:pagination:showing'), " ", _jsx("span", __assign({ className: "font-medium" }, { children: index }), void 0), " ", t('global:pagination:to'), ' ', _jsx("span", __assign({ className: "font-medium" }, { children: length }), void 0), " ", t('global:pagination:of'), ' ', _jsx("span", __assign({ className: "font-medium" }, { children: total }), void 0), " ", t('global:pagination:results')] }), void 0) }), void 0), _jsxs("div", __assign({ className: "flex-1 flex justify-between sm:justify-end space-x-2" }, { children: [_jsx("button", __assign({ onClick: handlePrev, className: clsx('relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50', currentPage <= 1 ? 'bg-gray-50 cursor-default opacity-70' : 'bg-white shadow-sm shadow-zinc-200'), disabled: currentPage <= 1 }, { children: t('global:prev') }), void 0), _jsx("button", __assign({ onClick: handleNext, className: clsx('relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50', currentPage === pages ? 'bg-gray-50 cursor-default opacity-70' : 'bg-white shadow-sm shadow-zinc-200'), disabled: currentPage === pages }, { children: t('global:next') }), void 0)] }), void 0)] }), void 0));
};
Pagination.displayName = 'Pagination';
