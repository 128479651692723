var en = {
    locale: {
        const: 'en-CA',
    },
    api: {
        EMAIL_MISSING: 'No email provided',
        NO_PASSWORD_PROVIDED: 'No password provided',
        USER_DOES_NOT_EXIST: 'Cannot find user with that email. Please try again.',
        ERROR_LOGGING_IN_USER: 'Please try logging in again.',
        TOKEN_IS_REQUIRED: 'No password reset token present. Please try again.',
        NEW_PASSWORD_IS_REQUIRED: 'Provide a new password.',
        PASSWORD_RESET_TOKEN_DOES_NOT_MATCH: 'Your password reset token is not valid for this account. Please try again.',
        PASSWORD_RESET_TOKEN_HAS_EXPIRED: 'Sorry, your password reset token has expired. Please try again.',
        INCORRECT_PASSWORD: 'Sorry, your password is incorrect',
        INVALID_LOGIN: 'Sorry, those credentials are not valid',
        TOKEN_IS_MISSING: 'Authentication token is missing from request.',
        INVALID_TOKEN: 'Authentication token is invalid.',
        NOT_AUTHORIZED_FOR_THIS_ACTIVITY: 'Sorry, you are not authorized for this activity.',
    },
    global: {
        client: 'M&M Food Market',
        app: 'M&M Flyer Ordering',
        view: 'View',
        copy: 'Copy',
        bulk: 'Bulk Add',
        edit: 'Edit',
        update: 'Update',
        delete: 'Delete',
        save: 'Save Changes',
        savethisflyer: 'Save for this flyer',
        saveallflyers: 'Save for ALL future flyers',
        thisflyer: ' for this flyer',
        allflyers: 'for all flyers',
        remove: 'Remove',
        addnew: 'Add New',
        cancel: 'Cancel',
        card: 'Card',
        list: 'List',
        participatingstores: 'Participating Stores',
        next: 'Next',
        prev: 'Previous',
        filter: 'Filter',
        pagination: {
            showing: 'Showing',
            to: 'to',
            of: 'of',
            results: 'results',
        },
        dateRange: 'Date Range',
        start: 'Start',
        end: 'End',
        clearSearch: 'Clear Search',
        reset: 'Reset',
        reload: 'Reload',
        home: 'Home',
        search: 'Search',
        searchtitle: 'Search Value',
        dashboard: 'Dashboard',
        static: 'Setup + Admin',
        back_to_login: 'Back to login',
        allUsers: 'All Users',
        allStores: 'All Stores',
        allStoreTypes: 'All Store Types',
        allStoreOwners: 'All Store Owners',
        selectStores: 'Add Stores To Flyer',
        bulkAddStores: 'Add Selected',
        yourStores: 'Your Stores',
        allZones: 'All Zones',
        allPlants: 'All Plants',
        allDistributors: 'All Distributors',
        allPostalStations: 'All Postal Stations',
        allFlyers: 'All Flyers',
        allFlyerTypes: 'All Flyer Types',
        allOfficeCopies: 'All Office Copies',
        allOfficeCopyRecipients: 'Office Copy Recipients',
        yourFlyers: 'Weekly Flyers',
        allProvinces: 'All Provinces',
        email: 'Email Address',
        password: 'Password',
        confirmpassword: 'Confirm Password',
    },
    resetPassword: {
        no_match: 'Passwords do not match.',
        reset_password_headline: 'Enter new password',
        PASSWORD_SUCCESS: 'Successfully reset your password.',
    },
    forgotPassword: {
        forgot_password_headline: 'Reset your password',
        password_email_success: 'Please check your email for a password reset link',
    },
    login: {
        forgot_password: 'Forgot your password?',
        title: 'Flyer Ordering System',
        headline: 'Log in to your account',
        log_in: 'Log in',
    },
    preferences: {
        edit_account: 'Edit your account',
        change_password: 'Change your password',
        currentPassword: 'Current password',
        newPassword: 'New password',
        confirmPassword: 'Confirm password',
        language: 'Language for Email',
        change_language: 'Change language',
        update_password: 'Update password',
        update_language: 'Update language',
        resetLanguage: 'LANGUAGE_SUCCESS',
    },
    flyers: {
        nationalflyer: 'National Flyer',
        regionaloptional: 'Regional Optional',
        regionalflyer: 'Regional Flyer',
        optionalflyer: 'Optional Flyer',
        raddar: 'RADDAR',
        Digitalflyer: 'Digital flyer',
        Monday: 'Monday',
        Tuesday: 'Tuesday',
        Wednesday: 'Wednesday',
        Thursday: 'Thursday',
        Friday: 'Friday',
        Saturday: 'Saturday',
        Sunday: 'Sunday',
    },
    nav: {
        signout: 'Sign Out',
        admin: 'Admin',
        users: {
            label: 'Users',
            description: 'Manage users on the site and their access.',
        },
        stores: {
            label: 'Stores',
            description: 'Access and edit the information about your store(s).',
        },
        storetypes: {
            label: 'Store Types',
            description: 'Access and edit the information about storetypes.',
        },
        storeowners: {
            label: 'Store Owners',
            description: 'Access and edit the information about storeowners.',
        },
        flyertype: {
            label: 'Flyer Types',
            description: 'Access and edit the information about flyertypes.',
        },
        zones: {
            label: 'Zones',
            description: 'Edit zones and their details (e.g. office copies).',
        },
        plants: {
            label: 'Plants',
            description: 'Edit plants and their details.',
        },
        officeCopies: {
            label: 'Office Copies',
            description: 'Edit office copies and their recipients.',
        },
        officeCopyRecipients: {
            label: 'Office Copy Recipients',
            description: 'Edit recipients for office copies.',
        },
        distributors: {
            label: 'Distributors',
            description: 'Edit distributors and their details.',
        },
        postalstations: {
            label: 'Postal Stations',
            description: 'Edit postal stations and their defaults.',
        },
        flyers: {
            label: 'Flyers',
            description: 'Create or update flyers.',
            flyerfor: 'Flyer for ',
        },
        provinces: {
            label: 'Provinces',
            description: 'Manage provinces.',
        },
        applicationlog: {
            label: 'Log Of Changes',
            description: 'Show and Export log of application changes',
        },
        bulkemail: {
            label: 'Bulk Email Sending',
            description: 'Sending Email To Active Users',
        },
        reports: {
            label: 'Reports + Exports',
            description: 'Produce reports or export data from the site.',
        },
        preferences: {
            label: 'Preferences',
            description: 'Manage preferences for your site login.',
        },
        help: {
            label: 'Help',
            description: 'View help pages on the site functions.',
        },
    },
    models: {
        store: {
            store: 'Store',
            number: 'Number',
            name: 'Name',
            active: 'Active',
            address: 'Store Address',
            tel: 'Telephone',
            zone: 'Zone',
            defaultVersion: 'Default Version',
            minimum: 'Minimum',
            owner: 'Owner',
            storecopies: 'Store Copies',
            contact: 'Contact Details',
            flyers: 'Flyers',
            defdist: 'Default Distributors',
            txtadddist: 'The distributors here will automatically be included any time a new flyer is added.',
            txtadddist2: 'To change distributors on existing flyers, use the Flyers list above!',
            distributor: 'Distributor',
            distributors: 'Distributors',
            adddist: 'Add Distributor',
            defpostal: 'Default Postal Walks',
            postalwalks: 'Postal Walks',
            InHomeDay: 'In Home Day',
            addpost: 'Add Postal Walk',
            txtaddpost: 'The postal walks here will automatically be included any time a new flyer is added.',
            txtaddpost2: 'To change postal walks on existing flyers, use the Flyers list above!',
            apt: 'Apt',
            bus: 'Bus',
            farm: 'Farm',
            house: 'House',
            selected: 'Selected',
            qty: 'Quantity',
            qtys: 'Quantities',
            save: 'Save Changes',
            flyerhistory: 'Flyer History',
        },
        flyer: {
            model: 'Flyer',
            sametypeflyer: 'Same Type Flyer',
            saledate: 'Sale Date',
            type: 'Type',
            size: 'Size',
            pages: 'Pages',
            format: 'Format',
            version: 'Version',
            storeqty: 'Store Qty',
            distqty: 'Dist Qty',
            postqty: 'Postal Qty',
            totalqty: 'Total Qty',
            total: 'Total',
            confirmed: 'Confirmed',
            unconfirmed: 'Uncomfirmed',
            locked: 'Flyer Locked',
        },
        distributor: {
            name: 'Name',
            qty: 'Quantity',
            copies: 'Copies',
        },
        postal: {
            name: 'Walk',
            selected: 'Selected',
            qty: 'Quantity',
            copies: 'Copies',
            house: 'House',
            farm: 'Farm',
            apt: 'Apt',
            bus: 'Bus',
        },
    },
    lists: {
        stores: {
            number: 'Number',
            name: 'Name',
            address: 'Address',
            type: 'Type',
            minimum: 'Min Qty',
            owner: 'Owner',
            active: 'Active',
        },
        flyers: {
            saledate: 'Date',
            type: 'Type',
            size: 'Size',
            pages: 'Pages',
            version: 'Version',
            storeqty: 'Store Qty',
            distqty: 'Dist Qty',
            postqty: 'Postal Qty',
            totalqty: 'Total Qty',
            confirmed: 'Confirmed',
            removeflyer: 'Remove Flyer',
        },
        flyerTypes: {
            type: 'FlyerType',
        },
        distributors: {
            name: 'Name',
            location: 'Location',
            stores: 'Stores',
            quarterfold: '1/4 Fold',
        },
        users: {
            name: 'Name',
            role: 'Role',
            stores: 'Stores',
            language: 'Language',
        },
        storeTypes: {
            name: 'Name',
            qty: 'Minimum Order',
        },
        applicationlog: {
            date: 'Date',
            user: 'User',
            Action: 'Action',
            Description: 'Description',
            Detail: 'Detail',
        },
    },
    email: {
        from: 'M&M Markets flyers',
        subject: 'Confirmation Of Flyer Order',
        line1: 'Orders are about to be locked for the',
        flyer: 'flyer',
        after: 'After',
        line2: 'you will no longer be able to update your order',
        line3: ' Your order is currently placed as',
        newspapers: 'Distributors (Newspapers)',
        newspapertotal: 'Distributor Copies',
        postalQTY: 'Postal Copies',
        storecopy: 'Store Copies',
        totalorderQty: 'Total Copies',
        sale: 'Sale',
        button: 'Go To Ordering Site',
        line4: 'Total flyer order of',
        line5: 'is below minimum order of',
        line6: 'copies required for this store',
        alert1: 'Franchisees are responsible for informing newspapers and distributors of any changes.',
        alert2: 'Changes must be completed a minimum of 27 days prior to the sale date.',
        alert1manually: "Alert1",
        alert2manually: "Alert2",
        store: 'Store',
    },
    pswd: {
        subject: 'Password Reset',
        textonly: 'To reset your password you must receive email as HTML!',
        instruction: 'Click the button below to reset your password.',
        button: 'Reset Password',
    },
    changes: {
        subject: 'Confirmation Of Changes',
        text: 'has made the changes indicated below on',
        description: 'Changes',
        details: 'Details',
    },
};
export default en;
