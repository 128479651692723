var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Navigation } from './Navigation';
import Notification from './Notification';
export var Layout = function (_a) {
    var children = _a.children;
    return (_jsxs(_Fragment, { children: [_jsx(Navigation, {}, void 0), _jsx("div", __assign({ className: "flex flex-col pt-32 md:pt-40 px-6 md:px-0 container mx-auto" }, { children: children }), void 0), _jsx(Notification, {}, void 0)] }, void 0));
};
Layout.displayName = 'Layout';
