import create from 'zustand';
export var useUIStore = create(function (set, get) { return ({
    menuOpen: false,
    overlayOpen: false,
    asList: true,
    setMenuOpen: function () { return set({ menuOpen: true, overlayOpen: false }); },
    setOverlayOpen: function () { return set({ overlayOpen: true, menuOpen: false }); },
    closeOverlays: function () { return set({ overlayOpen: false, menuOpen: false }); },
    viewAsList: function (val) { return set({ asList: val }); },
    notificationTitle: '',
    notificationMessage: '',
    notificationType: 'success',
    notificationActive: false,
    setNotification: function (title, message, type) {
        set(function () { return ({
            notificationTitle: title,
            notificationMessage: message,
            notificationType: type,
            notificationActive: true,
        }); });
    },
    clearNotification: function () {
        set(function () { return ({
            notificationTitle: '',
            notificationMessage: '',
            notificationType: 'warning',
            notificationActive: false,
        }); });
    },
}); });
