var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Link, useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';
import { GrFormClose } from 'react-icons/gr';
export var StoreCard = function (_a) {
    var _b = _a.storenumber, storenumber = _b === void 0 ? '' : _b, _c = _a.storename, storename = _c === void 0 ? '' : _c, _d = _a.city, city = _d === void 0 ? '' : _d, _e = _a.province, province = _e === void 0 ? '' : _e, _f = _a.manager, manager = _f === void 0 ? '' : _f, _g = _a.inactive, inactive = _g === void 0 ? false : _g;
    var url = useRouteMatch().url;
    return (_jsx("div", __assign({ className: "flex items-start rounded-lg shadow-lg hover:shadow-md transition-shadow  border border-gray-100" }, { children: _jsxs(Link, __assign({ to: url + "/" + storenumber, className: "p-6 inline-block w-full" }, { children: [_jsxs("p", __assign({ className: clsx('text-lg', inactive ? 'text-gray-400' : 'text-mmorange') }, { children: ["Store ", storenumber] }), void 0), _jsx("p", __assign({ className: "mb-1" }, { children: storename }), void 0), _jsxs("p", __assign({ className: "text-sm text-gray-500 mb-4" }, { children: [city, ", ", province] }), void 0), _jsxs("p", __assign({ className: "text-sm text-gray-500" }, { children: [_jsx("span", __assign({ className: "font-bold" }, { children: "Field Agent:" }), void 0), " ", manager] }), void 0), _jsx(Link, __assign({ to: "stores/edit/" + storenumber, className: "inline-block text-mmorange underline hover:text-opacity-70 text-sm mt-2" }, { children: "Edit" }), void 0), inactive && (_jsxs("div", __assign({ className: "absolute bg-gray-200 flex items-center top-0 right-0 p-2 text-gray-200" }, { children: [_jsx(GrFormClose, { className: "text-gray-500" }, void 0), _jsx("p", __assign({ className: "text-gray-500" }, { children: "Inactive" }), void 0)] }), void 0))] }), void 0) }), void 0));
};
StoreCard.displayName = 'StoreCard';
