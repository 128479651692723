var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useEffect } from 'react';
function useWindowLock() {
    var _a = __read(useState(0), 2), scrollTop = _a[0], setScrollTop = _a[1];
    var _b = __read(useState(0), 2), scrollValue = _b[0], setScrollValue = _b[1];
    useEffect(function () {
        var handleScroll = function () {
            setScrollTop(window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop);
        };
        window.addEventListener('scroll', handleScroll);
        return function () { return window.removeEventListener('scroll', handleScroll); };
    });
    var lock = function () {
        setScrollValue(scrollTop);
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        document.body.style.top = "-" + scrollTop + "px";
    };
    var unlock = function () {
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';
        window.scrollTo(0, scrollValue || 0 * -1);
    };
    return { scrollTop: scrollTop, lock: lock, unlock: unlock, scrollValue: scrollValue };
}
export default useWindowLock;
