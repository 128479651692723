var fr = {
    locale: {
        const: 'fr-CA',
    },
    api: {
        EMAIL_MISSING: 'Aucun e-mail fourni',
        NO_PASSWORD_PROVIDED: 'Aucun mot de passe fourni',
        USER_DOES_NOT_EXIST: "Impossible de trouver l'utilisateur avec cet e-mail. Veuillez réessayer.",
        ERROR_LOGGING_IN_USER: 'Veuillez réessayer de vous connecter.',
        TOKEN_IS_REQUIRED: 'Aucun jeton de réinitialisation de mot de passe présent. Veuillez réessayer.',
        NEW_PASSWORD_IS_REQUIRED: 'Fournissez un nouveau mot de passe.',
        PASSWORD_RESET_TOKEN_DOES_NOT_MATCH: "Votre jeton de réinitialisation de mot de passe n'est pas valide pour ce compte. Veuillez réessayer.",
        PASSWORD_RESET_TOKEN_HAS_EXPIRED: 'Désolé, votre jeton de réinitialisation de mot de passe a expiré. Veuillez réessayer.',
        INCORRECT_PASSWORD: 'Désolé, votre mot de passe est incorrect',
        INVALID_LOGIN: 'Désolé, ces identifiants ne sont pas valides',
        TOKEN_IS_MISSING: "Le jeton d'authentification est manquant dans la demande.",
        INVALID_TOKEN: "Le jeton d'authentification n'est pas valide.",
        NOT_AUTHORIZED_FOR_THIS_ACTIVITY: "Désolé, vous n'êtes pas autorisé pour cette activité.",
    },
    global: {
        client: 'Les Aliments M&M',
        app: 'Commande de circulaires M&M',
        view: 'Voir',
        copy: 'Copier',
        bulk: 'Ajouter en masse',
        edit: 'Éditer',
        update: 'Réviser',
        delete: 'Effacer',
        save: 'Sauvegarder',
        savethisflyer: 'Sauvegarder pour cette circulaire',
        saveallflyers: 'Sauvegarder pour présent et future circulaires',
        thisflyer: 'pour cette circulaire',
        allflyers: 'pour cette ET circulaires futures',
        allFlyerTypes: 'Tous les types de circulair',
        remove: 'Supprimer',
        addnew: 'Ajouter Nouveau',
        cancel: 'Annuler',
        card: 'Carte',
        list: 'Lister',
        participatingstores: 'Magasins Participants',
        next: 'Prochain',
        prev: 'Précédent',
        filter: 'Filtre',
        pagination: {
            showing: 'Affichage de',
            to: 'à',
            of: 'sur',
            results: 'résultats',
        },
        dateRange: 'Plage de dates',
        start: 'Début',
        end: 'Fin',
        clearSearch: 'Effacer la recherche',
        reset: 'Réinitialiser',
        reload: 'Recharger',
        home: 'Accueil',
        search: 'Rechercher',
        searchtitle: 'Valeur de recherche',
        dashboard: 'Tableau de bord',
        static: 'Configuration + Administration',
        back_to_login: 'Retour connexion',
        allUsers: 'Tous les utilisateurs',
        allStores: 'Tous les magasins',
        allStoreTypes: 'Tous les types de magasins',
        allStoreOwners: 'Tous les propriétaires de magasins',
        selectStores: 'Ajouter des magasins à la vente',
        bulkAddStores: 'Ajouter la sélection',
        yourStores: 'Vos magasins',
        allZones: 'Toutes les zones',
        allPlants: 'Toutes les usines',
        allDistributors: 'Tous les distributeurs',
        allPostalStations: 'Toutes les stations postales',
        allFlyers: 'Tous les circulaires',
        allOfficeCopies: 'Toutes les copies de bureau',
        allOfficeCopyRecipients: 'Destinataires de les copies bureautique',
        yourFlyers: 'Vos circulaires',
        allProvinces: 'Toutes les provinces',
        email: 'Adresse e-mail',
        password: 'Mot de passe',
        confirmpassword: 'Confirmez le mot de passe',
    },
    resetPassword: {
        no_match: 'Les mots de passe ne correspondent pas.',
        reset_password_headline: 'Entrez un nouveau mot de passe',
        PASSWORD_SUCCESS: 'Réinitialisez votre mot de passe avec succès',
    },
    forgotPassword: {
        forgot_password_headline: 'Réinitialisez votre mot de passe',
        password_email_success: 'Veuillez vérifier votre courrier électronique pour un lien de réinitialisation de mot de passe',
    },
    login: {
        forgot_password: 'Mot de passe oublié?',
        title: 'Système de commande de circulaires',
        headline: 'Connectez-vous à votre compte',
        log_in: 'Connexion',
    },
    preferences: {
        edit_account: 'Modifier votre compte',
        change_password: 'Changez le mot de passe',
        currentPassword: 'Mot de passe actuel',
        newPassword: 'Nouveau mot de passe',
        confirmPassword: 'Confirmez le mot de passe',
        language: 'Langue de E-mail',
        change_language: 'Changez le Langue',
        update_password: 'Réviser passe',
        update_language: 'Réviser langue',
        resetLanguage: 'LANGUE_Succès',
    },
    flyers: {
        nationalflyer: 'National Flyer',
        regionaloptional: 'Regional Optional',
        regionalflyer: 'Regional Flyer',
        optionalflyer: 'Optional Flyer',
        raddar: 'RADDAR',
        Digitalflyer: 'Digital flyer',
        Monday: 'Lundi',
        Tuesday: 'Mardi',
        Wednesday: 'Mercredi',
        Thursday: 'Jeudi',
        Friday: 'Vendredi',
        Saturday: 'Samedi',
        Sunday: 'Dimanche',
    },
    nav: {
        signout: 'Déconnexion',
        admin: 'Admin',
        users: {
            label: 'Utilisateurs',
            description: 'Gérer les utilisateurs sur le site et leurs accès.',
        },
        stores: {
            label: 'Magasins',
            description: 'Accéder et modifier les informations de vos magasins.',
        },
        storetypes: {
            label: 'Types de magasins',
            description: 'Modifier les informations sur les types de magasins.',
        },
        storeowners: {
            label: 'Propriétaires de magasins',
            description: 'modifier les informations sur les propriétaires de magasins.',
        },
        flyertype: {
            label: 'Types de circulaire',
            description: 'modifier les informations sur les propriétaires de circulaire.',
        },
        zones: {
            label: 'Zones',
            description: 'Modifier les zones et leurs détails (par exemple, les copies de bureau).',
        },
        bulkemail: {
            label: 'Ajouter en masse e-mail envoyer',
            description: 'Envoyer  e-mail To Actif utilisateurs',
        },
        plants: {
            label: 'Usines',
            description: 'Modifier les usines et leurs détails.',
        },
        officeCopies: {
            label: 'Copies de bureau',
            description: 'Modifier les copies de bureau et leurs destinataires.',
        },
        officeCopyRecipients: {
            label: 'Destinataires de la copie bureautique',
            description: 'Modifier les destinataires de copies de bureau.',
        },
        distributors: {
            label: 'Distributeurs',
            description: 'Modifier les distributeurs et leurs coordonnées.',
        },
        postalstations: {
            label: 'Stations postales',
            description: 'Modifier les stations postales et leurs valeurs par défaut.',
        },
        flyers: {
            label: 'Circulaires',
            description: 'Créer ou mettre à jour des circulaires.',
            flyerfor: 'Circulaire pour ',
        },
        provinces: {
            label: 'Provinces',
            description: 'Gérer les provinces.',
        },
        reports: {
            label: 'Rapports + Exportations',
            description: 'Produire des rapports ou exporter des données depuis le site.',
        },
        preferences: {
            label: 'Préférences',
            description: 'Gérer les préférences pour votre connexion au site.',
        },
        help: {
            label: 'Aider',
            description: "Afficher les pages d'aide sur les fonctions du site.",
        },
    },
    models: {
        store: {
            store: 'Magasin',
            number: 'Numéro',
            name: 'Nom',
            active: 'Actif',
            address: 'Adresse du magasin',
            tel: 'Téléphone',
            zone: 'Zone',
            defaultVersion: 'Version Défaut',
            minimum: 'Minimum',
            owner: 'Titulaire',
            storecopies: 'Copies Magasin',
            contact: 'Détails du Contact',
            flyers: 'Circulaires',
            defdist: 'Distributeurs Défaut',
            distributor: 'Distributeur',
            distributors: 'Distributeurs',
            adddist: 'Ajouter Distributeur',
            txtadddist: "Les distributeurs ici seront automatiquement inclus chaque fois qu'un nouveau circulaire sera ajouté.",
            txtadddist2: 'Pour changer de distributeurs sur des flyers existants, utilisez la liste Circulaires ci-dessus!',
            defpostal: 'Postale Défaut',
            postalwalks: 'Itinéraires Postaux ',
            InHomeDay: 'Journée livraison poste',
            addpost: 'Ajouter Itinéraires Postaux',
            txtaddpost: "Les Itinéraires Postaux  ici seront automatiquement inclus chaque fois qu'un nouveau circulaire sera ajouté.",
            txtaddpost2: 'Pour changer Itinéraires Postaux  sur des flyers existants, utilisez la liste Circulaires ci-dessus!',
            apt: 'Apt',
            bus: 'Bus',
            farm: 'Farm',
            house: 'House',
            selected: 'Choisi',
            qty: 'Quantité',
            qtys: 'Quantités',
            save: 'Sauvegarder',
            flyerhistory: 'Historique Des Circulaires',
        },
        flyer: {
            model: 'Circulaire',
            sametypeflyer: 'Same Type Circulaires',
            saledate: 'Date de vente',
            type: 'Type',
            size: 'Taille',
            pages: 'Pages',
            format: 'Format',
            version: 'Version',
            storeqty: 'Qté Magasin',
            distqty: 'Qté Dist',
            postqty: 'Qté Postaux',
            totalqty: 'Qté Totale',
            total: 'Totale',
            confirmed: 'Confirmé',
            unconfirmed: 'Non Confirmé',
            locked: 'Flyer Locked',
        },
        distributor: {
            name: 'Nom',
            qty: 'Quantité',
            copies: 'Copies',
        },
        postal: {
            name: 'ITINÉRAIRES',
            selected: 'Choisi',
            qty: 'Quantité',
            copies: 'Copies',
            house: 'Maisons',
            farm: 'Fermes',
            apt: 'Appartement',
            bus: 'Entreprises',
        },
    },
    lists: {
        stores: {
            number: 'Numéro',
            name: 'Nom',
            address: 'Addresse',
            type: 'Type',
            owner: 'Propriétaire',
            minimum: 'Qté Min',
            active: 'Actifs',
        },
        flyers: {
            saledate: 'Date',
            type: 'Type',
            size: 'Taille',
            pages: 'Pages',
            version: 'Version',
            storeqty: 'Qté Magasin',
            distqty: 'Qté Dist',
            postqty: 'Qté Postale',
            totalqty: 'Qté Totale',
            confirmed: 'Confirmé',
            removeflyer: 'Supprimer',
        },
        flyerTypes: {
            type: 'FlyerType',
        },
        distributors: {
            name: 'Nom',
            location: 'Emplacement',
            stores: 'Magasins',
            quarterfold: 'Pli en quatre',
        },
        users: {
            name: 'Nom',
            role: 'Rôle',
            stores: 'Magasins',
            language: 'Langue',
        },
        storeTypes: {
            name: 'Nom',
            qty: 'Commande Minimale',
        },
    },
    email: {
        from: 'M&M Markets circulaires',
        subject: 'Confirmation de la commande de circulaires',
        line1: "Les commandes sont sur le point d'\u00EAtre verrouill\u00E9es pour le",
        flyer: 'circulaires',
        after: 'Après',
        line2: 'vous ne pourrez plus mettre à jour votre commande',
        line3: ' Votre commande est actuellement passée en tant que',
        newspapers: 'Distributeurs (Journaux)',
        newspapertotal: "Copies du distributeur",
        postalQTY: 'Copies Postales',
        storecopy: 'Copies Magasins',
        totalorderQty: 'Total Commande',
        sale: 'Vente',
        button: 'Aller sur le site de commande',
        line4: 'Total circulaires Commande',
        line5: 'En dessous du minimum de commande requis',
        line6: 'Copies requise pour ce magasin',
        alert1: "Les franchis\u00E9s sont responsables d'informer les journaux et les distributeurs de tout changements.",
        alert2: 'Les modifications doivent être effectuées au moins 27 jours avant la date de la vente.',
        alert1manually: "Alert1",
        alert2manually: "Alert2",
        store: 'Magasins',
    },
    pswd: {
        subject: 'Réinitialisation du mot de passe',
        textonly: 'Pour réinitialiser votre mot de passe, vous devez recevoir un e-mail au format HTML!',
        instruction: 'Cliquez sur le bouton ci-dessous pour réinitialiser votre mot de passe.',
        button: 'Réinitialisation du mot de passe',
    },
    changes: {
        subject: 'Confirmation des modifications',
        text: 'a apporté les modifications indiquées ci-dessous sur',
        description: 'Changements',
        details: 'Détails',
    },
};
export default fr;
