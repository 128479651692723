var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import omit from 'lodash.omit';
import pickBy from 'lodash.pickby';
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { PlusSmIcon as PlusSmIconSolid, DocumentRemoveIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { addDays, parse, format } from 'date-fns';
import { useUIStore } from '../state';
import useAPI from '../hooks/useAPI';
import { Loading, Pagination, Checkbox, Input, Breadcrumbs, Button, DisplaySwitch } from '../Components/index';
export var DisplayView = function (_a) {
    var _b = _a.searchTitle, searchTitle = _b === void 0 ? 'Search Value' : _b, endpoint = _a.endpoint, _c = _a.shouldParseRegions, shouldParseRegions = _c === void 0 ? false : _c, _d = _a.filterByDate, filterByDate = _d === void 0 ? false : _d, _e = _a.filterByPcode, filterByPcode = _e === void 0 ? false : _e, _f = _a.filterByFsa, filterByFsa = _f === void 0 ? false : _f, _g = _a.filterActive, filterActive = _g === void 0 ? false : _g, children = _a.children, _h = _a.title, title = _h === void 0 ? '' : _h, _j = _a.model, model = _j === void 0 ? '' : _j, _k = _a.showFilters, showFilters = _k === void 0 ? true : _k, _l = _a.showBreadCrumb, showBreadCrumb = _l === void 0 ? true : _l, _m = _a.justPaging, justPaging = _m === void 0 ? false : _m, _o = _a.canEdit, canEdit = _o === void 0 ? false : _o;
    var pathname = useLocation().pathname;
    var _p = useForm(), control = _p.control, handleSubmit = _p.handleSubmit, register = _p.register, watch = _p.watch, reset = _p.reset, formState = _p.formState;
    var t = useTranslation().t;
    var _q = useAPI(), get = _q.get, loading = _q.loading;
    var _r = __read(useState(null), 2), pageData = _r[0], setPageData = _r[1];
    var _s = __read(useState([]), 2), data = _s[0], setData = _s[1];
    var _t = __read(useState([]), 2), allData = _t[0], setAllData = _t[1];
    var _u = __read(useState(false), 2), showClear = _u[0], setShowClear = _u[1];
    var _v = __read(useState(''), 2), dataParams = _v[0], setParams = _v[1];
    var _w = __read(useState(false), 2), error = _w[0], setError = _w[1];
    var _x = __read(useState([]), 2), regions = _x[0], setRegions = _x[1];
    var _y = __read(useState(false), 2), activeOnly = _y[0], setActive = _y[1];
    var asList = useUIStore(function (state) { return state.asList; });
    var start = watch('startDate');
    var end = watch('endDate');
    useEffect(function () {
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var dist;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get(endpoint + "?page=1")];
                    case 1:
                        dist = _a.sent();
                        if (dist.success) {
                            setData(dist.data.data);
                            setAllData(dist.data.total);
                            if (shouldParseRegions) {
                                setRegions(dist.data.regions);
                            }
                            setActive(false);
                            setPageData(dist.data.pages);
                        }
                        else {
                            setError(true);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        fetchData();
    }, []);
    var handleClear = function () { return __awaiter(void 0, void 0, void 0, function () {
        var dist;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, get(endpoint + "?page=" + Number(pageData === null || pageData === void 0 ? void 0 : pageData.currentPage))];
                case 1:
                    dist = _a.sent();
                    if (dist.success) {
                        setData(dist.data.data);
                        setAllData(dist.data.total);
                        if (shouldParseRegions) {
                            setRegions(dist.data.regions);
                        }
                        setPageData(dist.data.pages);
                        reset();
                        setShowClear(false);
                        setParams('');
                    }
                    else {
                        setError(true);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var parseRegions = function (dataFeed) {
        var data = dataFeed.map(function (info) { return ({
            label: info.label,
            name: info.name,
        }); });
        var ids = data.map(function (o) { return o.name; });
        var filtered = data.filter(function (_a, index) {
            var name = _a.name;
            return !ids.includes(name, index + 1);
        });
        setRegions(filtered);
    };
    var handleNext = function () { return __awaiter(void 0, void 0, void 0, function () {
        var dist;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!pageData) return [3 /*break*/, 2];
                    return [4 /*yield*/, get(endpoint + "?page=" + (Number(pageData === null || pageData === void 0 ? void 0 : pageData.currentPage) + 1) + "&" + dataParams)];
                case 1:
                    dist = _a.sent();
                    if (dist.success) {
                        setData(dist.data.data);
                        setAllData(dist.data.total);
                        if (shouldParseRegions) {
                            parseRegions(dist.data.regions);
                        }
                        setPageData(dist.data.pages);
                    }
                    else {
                        setError(true);
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var handlePrev = function () { return __awaiter(void 0, void 0, void 0, function () {
        var dist;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!pageData) return [3 /*break*/, 2];
                    return [4 /*yield*/, get(endpoint + "?page=" + (Number(pageData === null || pageData === void 0 ? void 0 : pageData.currentPage) - 1) + dataParams)];
                case 1:
                    dist = _a.sent();
                    if (dist.success) {
                        setData(dist.data.data);
                        setAllData(dist.data.total);
                        if (shouldParseRegions) {
                            parseRegions(dist.data.regions);
                        }
                        setPageData(dist.data.pages);
                    }
                    else {
                        setError(true);
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    function updateActive(value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                    setActive(value);
                }
                catch (err) {
                    console.log(err);
                }
                return [2 /*return*/];
            });
        });
    }
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var search, pcode, fsa, startDate, endDate, active, regions, params, val, dist;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    search = data.search, pcode = data.pcode, fsa = data.fsa, startDate = data.startDate, endDate = data.endDate, active = data.active;
                    regions = omit(data, ['search', 'pcode', 'fsa', 'startDate', 'endDate', 'active']);
                    if (!(regions || search || pcode || fsa || startDate || endDate || active)) return [3 /*break*/, 2];
                    params = '';
                    if (active) {
                        params += "&active=" + active;
                    }
                    if (search) {
                        params += "&search=" + search;
                    }
                    if (pcode) {
                        params += "&pcode=" + pcode;
                    }
                    if (fsa) {
                        params += "&fsa=" + fsa;
                    }
                    if (startDate) {
                        params += "&startDate=" + startDate;
                    }
                    if (endDate) {
                        params += "&endDate=" + endDate;
                    }
                    val = pickBy(regions);
                    if (Object.keys(val).length) {
                        params += "&province=" + Object.keys(val).join(',');
                    }
                    return [4 /*yield*/, get(endpoint + "?page=1" + params)];
                case 1:
                    dist = _a.sent();
                    setParams(params);
                    setData(dist.data.data);
                    setAllData(dist.data.total);
                    setShowClear(true);
                    if (shouldParseRegions) {
                        parseRegions(dist.data.regions);
                    }
                    setPageData(dist.data.pages);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var renderData = function () {
        if (loading) {
            _jsx("div", __assign({ className: "flex justify-center" }, { children: _jsx(Loading, {}, void 0) }), void 0);
        }
        else if (!loading && data.length) {
            return (_jsxs("div", __assign({ className: "space-y-4 mb-6" }, { children: [(pageData && showFilters && (_jsx(Pagination, { length: pageData.end, total: pageData.total, pages: pageData.pages, index: pageData.start, currentPage: pageData.currentPage, handleNext: handleNext, handlePrev: handlePrev }, void 0))) ||
                        (justPaging && pageData && (_jsx(Pagination, { length: pageData.end, total: pageData.total, pages: pageData.pages, index: pageData.start, currentPage: pageData.currentPage, handleNext: handleNext, handlePrev: handlePrev }, void 0))), formState.isSubmitted && showClear ? (_jsx("button", __assign({ onClick: handleClear, className: "flex justify-center px-2 py-1.5 text-sm font-medium text-blue-900 bg-blue-100 border border-blue-900 border-opacity-40 rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 shadow-md shadow-blue-100" }, { children: t('global:clearSearch') }), void 0)) : null, children({
                        dataFeed: data,
                        asList: asList,
                        AllData: allData,
                    }), (pageData && showFilters && (_jsx(Pagination, { length: pageData.end, total: pageData.total, pages: pageData.pages, index: pageData.start, currentPage: pageData.currentPage, handleNext: handleNext, handlePrev: handlePrev }, void 0))) ||
                        (justPaging && pageData && (_jsx(Pagination, { length: pageData.end, total: pageData.total, pages: pageData.pages, index: pageData.start, currentPage: pageData.currentPage, handleNext: handleNext, handlePrev: handlePrev }, void 0)))] }), void 0));
        }
        else {
            return (_jsxs("div", __assign({ className: "flex flex-col justify-center items-center text-center max-w-4xl mx-auto space-y-6 mt-16" }, { children: [_jsx(DocumentRemoveIcon, { className: "h-16 w-16 text-gray-400 drop-shadow-md", "aria-hidden": true }, void 0), _jsxs("h2", __assign({ className: "text-2xl text-gray-700" }, { children: ["No ", model, " found."] }), void 0), canEdit && (_jsxs(Link, __assign({ to: pathname + "/new", className: "flex items-center justify-center px-4 py-2 font-medium text-emerald-900 bg-emerald-100 border border-emerald-900 border-emerald-20 rounded-md hover:bg-emerald-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-emerald-500 shadow-lg shadow-emerald-100" }, { children: [_jsx(PlusSmIconSolid, { className: "h-4 w-4 mr-1", "aria-hidden": "true" }, void 0), " ", t('global:addnew')] }), void 0))] }), void 0));
        }
    };
    return (_jsxs("div", { children: [showBreadCrumb ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "flex items-center justify-between" }, { children: _jsx(Breadcrumbs, { links: [{ name: title, href: pathname, current: true }] }, void 0) }), void 0), _jsxs("header", __assign({ className: "flex items-baseline justify-between mb-6 pb-3 border-b border-gray-200" }, { children: [_jsx("h1", __assign({ className: "text-2xl" }, { children: title }), void 0), !loading && !!data.length && _jsx(DisplaySwitch, {}, void 0)] }), void 0)] }, void 0)) : null, _jsxs("div", __assign({ className: "flex" }, { children: [showFilters && !loading && !!data.length && (_jsxs("aside", __assign({ className: "pt-4 pb-24 space-y-8 divide-y" }, { children: [canEdit && showBreadCrumb && (_jsxs(Link, __assign({ to: pathname + "/new", className: "flex justify-center px-2 py-1.5 text-sm font-medium text-emerald-900 bg-emerald-100 border border-emerald-900 border-opacity-40 rounded-md hover:bg-emerald-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-emerald-500 shadow-md shadow-emerald-100" }, { children: [_jsx(PlusSmIconSolid, { className: "h-4 w-4 mr-1", "aria-hidden": "true" }, void 0), " ", t('global:addnew')] }), void 0)), _jsxs("div", __assign({ className: clsx(canEdit ? 'pt-5' : 'pt-0') }, { children: [_jsx("h2", __assign({ className: "text-xl mb-3 text-gray-700" }, { children: t('global:filter') }), void 0), _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit), className: "space-y-8 flex flex-col" }, { children: [filterActive ? (_jsx("div", { children: _jsx(Controller, { control: control, name: 'active', render: function (_a) {
                                                        var _b = _a.field, onChange = _b.onChange, value = _b.value, name = _b.name;
                                                        return (_jsx(Checkbox, { onChange: onChange, value: value, label: 'Active', id: name }, void 0));
                                                    } }, 'active') }, void 0)) : null, _jsx("div", { children: _jsx(Controller, { control: control, name: "search", render: function (_a) {
                                                        var _b = _a.field, onChange = _b.onChange, value = _b.value, name = _b.name;
                                                        return (_jsx(Input, { value: value, onChange: onChange, type: "search", id: name, label: searchTitle }, void 0));
                                                    } }, void 0) }, void 0), filterByPcode ? (_jsx("div", { children: _jsx(Controller, { control: control, name: "pcode", render: function (_a) {
                                                        var _b = _a.field, onChange = _b.onChange, value = _b.value, name = _b.name;
                                                        return (_jsx(Input, { value: value, onChange: onChange, type: "search", id: name, label: "Postal Code" }, void 0));
                                                    } }, void 0) }, void 0)) : null, filterByFsa ? (_jsx("div", { children: _jsx(Controller, { control: control, name: "fsa", render: function (_a) {
                                                        var _b = _a.field, onChange = _b.onChange, value = _b.value, name = _b.name;
                                                        return (_jsx(Input, { value: value, onChange: onChange, type: "search", id: name, label: "FSA" }, void 0));
                                                    } }, void 0) }, void 0)) : null, filterByDate ? (_jsxs("div", { children: [_jsx("p", __assign({ className: "mb-3 text-gray-700" }, { children: t('global:dateRange') }), void 0), _jsxs("div", __assign({ className: "space-y-3" }, { children: [_jsxs("div", __assign({ className: "text-left w-full" }, { children: [_jsx("label", __assign({ htmlFor: "startDate", className: "block text-sm font-medium text-gray-700 mb-1" }, { children: t('global:start') }), void 0), _jsx("input", __assign({}, register('startDate'), { max: end ? end : format(new Date(), 'yyyy-MM-dd'), type: "date", className: "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" }), void 0)] }), void 0), _jsxs("div", __assign({ className: "text-left w-full" }, { children: [_jsx("label", __assign({ htmlFor: "endDate", className: "block text-sm font-medium text-gray-700 mb-1" }, { children: t('global:end') }), void 0), _jsx("input", __assign({}, register('endDate'), { type: "date", min: start ? format(addDays(parse(start, 'yyyy-MM-dd', new Date()), 1), 'yyyy-MM-dd') : '', className: "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm shadow-zinc-200 placeholder-gray-400 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm" }), void 0)] }), void 0)] }), void 0)] }, void 0)) : null, shouldParseRegions && regions.length ? (_jsxs("div", { children: [_jsx("h3", __assign({ className: "text-darkblue mb-2" }, { children: t('nav:provinces:label') }), void 0), _jsx("div", __assign({ className: "space-y-4" }, { children: regions.map(function (region) { return (_jsx(Controller, { control: control, name: region.name, render: function (_a) {
                                                                var _b = _a.field, onChange = _b.onChange, value = _b.value, name = _b.name;
                                                                return (_jsx(Checkbox, { onChange: onChange, value: value, label: region.label, id: name }, void 0));
                                                            } }, region.name)); }) }), void 0)] }, void 0)) : null, _jsx(Button, __assign({ type: "submit" }, { children: t('global:search') }), void 0)] }), void 0)] }), void 0)] }), void 0)), _jsx("main", __assign({ className: clsx('flex-1', showFilters && !loading && !!data.length && 'w-4/6 ml-24') }, { children: renderData() }), void 0)] }), void 0)] }, void 0));
};
