var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import clsx from 'clsx';
var strongPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
var mediumPassword = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})');
export var PasswordStrength = function (_a) {
    var value = _a.value, canPass = _a.canPass;
    var _b = __read(useState(null), 2), strength = _b[0], setStrength = _b[1];
    useEffect(function () {
        if (value) {
            if (strongPassword.test(value)) {
                setStrength('strong');
                canPass(true);
            }
            else if (mediumPassword.test(value)) {
                setStrength('medium');
                canPass(true);
            }
            else {
                setStrength('weak');
                canPass(true);
            }
        }
        else {
            setStrength(null);
            canPass(false);
        }
    }, [value]);
    return strength ? (_jsx("div", __assign({ className: clsx('uppercase', 'rounded-md', 'text-xs', 'w-full', 'py-1', 'mb-3', 'text-center', 'border', 'text-white', {
            'bg-red-500': strength === 'weak',
            'bg-blue-500': strength === 'medium',
            'bg-green-500': strength === 'strong',
        }) }, { children: strength }), void 0)) : null;
};
